<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Financeiro",
        title: "Pagar e Receber",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/PayableReceivableOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "recipientName",
            title: "Destinatário",
            type: "text",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "document",
            title: "Documento",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "typePaymentName",
            title: "Pagamento",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "costCenterName",
            title: "C Custo",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "chartOfAccountsName",
            title: "P Conta",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "bankAccountName",
            title: "Conta Bancária",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "dueDate",
            title: "Dt Vencimento",
            type: "date",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "competenceDate",
            title: "Dt Competência",
            type: "date",
            selected: false,
            requiredOData: false,
          },
          {
            field: "value",
            title: "Valor",
            type: "currency",
            selected: true,
            requiredOData: false,
          },
          {
            field: "paidName",
            title: "Recebido",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "conciliationDate",
            title: "Dt Pgto",
            type: "date",
            selected: false,
            requiredOData: false,
          },
          {
            field: "discount",
            title: "Desconto",
            type: "currency",
            selected: false,
            requiredOData: false,
          },
          {
            field: "fees",
            title: "Multa",
            type: "currency",
            selected: false,
            requiredOData: false,
          },
          {
            field: "general",
            title: "Geral",
            type: "currency",
            selected: false,
            requiredOData: false,
          },
        ],
        rules: [
          {
            name: "dueDate",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
              filterByField: [
                { text: "Data de Vencimento", value: "dueDate" },
                { text: "Data de Competência", value: "competenceDate" },
                { text: "Data de Pagamento", value: "conciliationDate" },
              ],
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "type",
            title: "Tipo",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Pagar", value: 1 },
              { title: "Receber", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "recipientId",
            title: "Destinatário",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/finance/payable-receivable/select-all-recipient",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "description",
            title: "Descrição",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "document",
            title: "Documento",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "value",
            title: "Valor",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "costCenterId",
            title: "Centro de Custo",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/finance/cost-center/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "chartOfAccountsId",
            title: "Plano de Conta",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/finance/chart-of-accounts/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "typePaymentId",
            title: "Pagamento",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/finance/type-payment/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "bankAccountId",
            title: "Conta Bancária",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/finance/bank-account/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "paid",
            title: "Recebido/Pago",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Sim", value: true },
              { title: "Não", value: false },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "discount",
            title: "Desconto",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "fees",
            title: "Multa",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "total",
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Valor",
                value: "sum_Value",
                aggregate: {
                  title: "Total Valor",
                  value: "sumValue",
                  type: "currency",
                },
              },
              {
                title: "Total Desconto",
                value: "sum_Discount",
                aggregate: {
                  title: "Total Desconto",
                  value: "sumDiscount",
                  type: "currency",
                },
              },
              {
                title: "Total Multa",
                value: "sum_Fees",
                aggregate: {
                  title: "Total Multa",
                  value: "sumFees",
                  type: "currency",
                },
              },
              {
                title: "Total Geral",
                value: "sum_General",
                aggregate: {
                  title: "Total Geral",
                  value: "sumGeneral",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
